import { BaseComponent } from 'project/general';

class PaginationComponent extends BaseComponent {

    static getNamespace() {
        return 'pagination';
    }

    onInit() {
        let linkCount = this.element.querySelectorAll('a')
        if (linkCount.length < 1) {
            this.element.classList.add('results__paging--hide');
        }
    }
}

export default PaginationComponent;

