import React from 'react';

export const ServerErrors = ({ errors }) => {
    if (!Array.isArray(errors)) return null;

    return (
        <ul className="product-errors">
            {
                errors.map((error, i) => {
                    return <li
                        className="product-errors__li paragraph-6"
                        key={`${error.id}-${i}`}
                    >{error.message}</li>;
                })
            }
        </ul>
    );
};
