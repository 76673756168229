import { dcFactory } from 'dc';
import NasFormComponent from './js/nas-form.component';
import { Wizard } from './js/wizard/wizard.jsx';

import './scss/index.scss';
import './scss/html-input.scss';

export {
    NasFormComponent, Wizard
};
